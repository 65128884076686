import './styles/index.css'
import {Box, Container, Typography} from "@mui/material";
import ExpandingDot from "./components/ExpandingDot";

function App() {
  return (
    <Box style={{backgroundImage: "url(\"/public/background halftones-02.jpg\")"}}>
      <a href={"/moreinfo"} style={{color: "black"}} >
        <Typography variant={"h6"} align={"right"} fontWeight={"bold"}>Get MORE INFO</Typography>
      </a>
      <Container>
        <img src={"title.png"} alt={"Title"} style={{width: "100%"}}/>
        <img src={"second title.png"} alt={"Title"} style={{width: "100%"}}/>
        <Box style={{ position: 'relative', marginTop: "-30%", marginLeft: "-10%"}}>
          <img className={"spiral"} src={"./spiral-03.png"}  alt={"Spiral"} style={{ maxWidth: '100%', height: 'auto' }}/>
          <Typography variant={"h4"} fontFamily={"Helvetica"} fontWeight={"bold"} style={{position: "absolute", top: "14%", left: "58%"}}>START</Typography>
          <Box position="absolute" top="16%" left="62%">
            <ExpandingDot
              imageSrc="./illustrations/illustration mass media.png"
              title={"Mass Media Japanese, anime, manga"}
              text="JK is an abbreviation of Joshi Kosei meaning High school girls. JKs are often oversexualized in Japanese animations (anime), comics (manga), video games, etc. They are drawn with exaggerated sexual features, short revealing skirts, and eroticized manners, commonly known as fanservice."
            />
          </Box>
          <Box position="absolute" top="25%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration uniforms.png"
              title={"History of Fetishism of Japanese School Uniforms"}
              text="School uniforms (seifuku) marked a revolution for Japanese females for the practicality and nationalist idealism they provided, especially within the post-war historical context of the time. However, since the 1960s, their frequent portrayal in erotic media has rendered them into sex symbols to be fetishized."
            />
          </Box>
          <Box position="absolute" top="34%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration fixed images.png"
              title={"Stereotypes & Fixed images"}
              text="The one-dimensional media depiction manifested into fixed images of high school girls: innocent and fragile young girls conforming to the tradition, rebellious behaviors of uncultured deviants (known as KoGaru), etc. Many media forms enforce these images with hypersexual narratives, hence further misrepresenting Japanese school girls."
            />
          </Box>
          <Box position="absolute" top="43%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration held hands.png"
              title={"Compensated Dating"}
              text={`Translated from the Japanese word, "Enjo-kōsai" Compensated Dating is a term used to describe young women (often school girls) going on dates with older men in exchange for money or gifts. Sometimes this involves a walk around the block or a drink at a bar. Often, it escalates to sex — child prostitution by another name.`}
            />
          </Box>
          <Box position="absolute" top="50%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration idol culture.png"
              title={"JK Band"}
              text="JK bands are pop groups made up (of school-age girls who perform at crowded venues at night. Their audience is mostly men who are older than them. JK fans usually have a favorite girl, and they’re fiercely devoted to her. After the show, the fans have an opportunity to meet, speak, and have handshakes with the girls, and this physical encounter perpetuates their fantasy."
            />
          </Box>
          <Box position="absolute" top="57%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration jk cafe.png"
              title={"JK Cafe"}
              text="There are 300 specialist JK cafés in Japan - around 5,000 high school girls as young as 15 work in these places, serving drinks and talking with older men. An amount of 40 euros will get you a chat of 40 minutes with a girl of your choice and free booze."
            />
          </Box>
          <Box position="absolute" top="64%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration soft.png"
              title={"Soft Porn"}
              text={`Until 2014 child pornography was legal to own in Japan. The law has changed, but the sexualization of minors continues. "Chaku Ero", which means "erotically clothed" is a kind of soft porn - it doesn’t involve nudity, but it is often overtly sexual. As long as these photos don’t show the child’s naked genitals, buttocks, or chest, it isn’t categorized as child pornography in Japan.`}
            />
          </Box>
          <Box position="absolute" top="70%" left="62%" >
            <ExpandingDot
              imageSrc="./illustrations/illustration social issues.png"
              title={"Prostitution & Teen pregnancy"}
              text="The report, an annual review of human trafficking in Japan, singled out the JK walking dates, saying they are often fronts for underage prostitution. This practice not only increases the risk of unintended teenage pregnancies but also makes it challenging for girls in the JK business to seek abortions, especially if they are struggling financially. It is unfortunate that high school girls who do become pregnant are often expelled from school."
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default App;
