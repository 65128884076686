import {Box, Container, Stack, Typography} from "@mui/material";

export default function MoreInfo() {
  return (
    <>
      <a href={"/"} style={{color: "black"}}>
        <Typography variant={"h6"} align={"right"} fontWeight={"bold"}>Back To Project</Typography>
      </a>
      <Container>
        <br />
        <br />

        <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"space-between"}>
          <Typography variant={"h2"} fontWeight={"bold"}>ABOUT THE PROJECT</Typography>
          <Typography>made by Juheui Kim, Lan Dao & Romy Gommers</Typography>
        </Stack>

        <br />
        <br />
        <br />

        <Typography>
          The topic is the fetishist fantasies created by the media about high school girls in Japan. We investigated the people with power behind this huge system and the consequences for high school girls. Several parties are profiting from these kinds of imagery; companies that produce manga, anime, and movies and artists who create for them. These depictions of girls are catered to a specific group of consumers, known as otakus*. Important to point out is that almost all the parties involved are mainly men, profiting from the
          objectification of girls.  High school girls are often portrayed in the media as contradicting stereotypes. They are depicted as pure, fragile, inexperienced, and yet overtly sexual, erotic, and seductive. This creates not only unrealistic expectations for girls but also results in controversial practices such as compensated
          dating* and cafes exclusively staffed with high school girls for entertainment. In extreme cases, it leads to
          harmful practices such as (child) prostitution and unwanted teen pregnancies.
          <br/>
          <br/>
          The phenomenon of sexualizing high school girls is happening in modern-day Japan. This problem is not unique to Japan only. These problems stem from the objectification of women and deeply rooted misogyny, which happens all around the world. Through an accumulation of events in the past, this sexualization has become so normalized.  It started when girls started first started going to school and worsened during the Kogal movement* in the 1990s.
          <br/>
          <br/>
          This sexualization of women has been escalating through the fast-paced production and distribution of modern-day media. The consequences of this problem barely resurface in politics and mainstream media. And when it does come to light, it is often not treated with the urgency it so desperately needs. In fact, the severity of these issues is often downplayed, which allows the normalization of inherent pedophilia.
          <br/>
          <br/>
          This counter-mapping is made for viewers who are not aware of the sexualization of Japanese high school girls that is happening. Phenomena like this create deep and real consequences for their victims. By shedding a light on this specific problem, we ask the viewer to not tolerate and participate in the redistribution of these
          images. We hope to make artists/creators aware that their works could be used to shape this twisted perception of minors via entertainment industries. Simultaneously, we beg artists to resist and oppose the creation and distribution of images that perpetuate these fantasies of young girls.
          <br/>
          <br/>
          Considering how the accessibility of the internet has contributed greatly to the phenomenon of the sexualization of high school girls, we consulted mostly online resources such as documentaries, video essays, and academic literature. By depicting these events in the form of a downward spiral, we want to show the worsening consequences. The end result is a website because the media used also travels to its consumers mainly online. The illustrations tap into the styles of the media that are used for portraying high school girls this way.
        </Typography>
        <Typography color={"#cc8b9a"}>
          <br/>
          <br/>
          *Otaku = (in Japan) a young person who is obsessed with computers or particular aspects of popular culture to the detriment of their social skills.
          <br/>
          <br/>
          *Compensated dating = practices of girls dating older men for expensive goods and/or money. They go on “normal” dates such as taking walks and going to restaurants. In extreme cases, these dates end
          in hotels and sex
          <br/>
          <br/>
          *Kogal movement = movement in the 1990s in which high school girls went against the norms and acted cruelly and rebelliously. This movement is often depicted by school girls squatting on the streets, whilst wearing their school uniform.
        </Typography>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Typography align={"right"}>
          CITATIONS
          <br/>
          <br/>
          Simon Ostrovsky (Director). (2015) Schoolgirls for Sale in Japan (Documentary). VICE News
          <br/>
          <br/>
          Tash Reith-Banks. (2019, June 20). Schoolgirls for sale: why Tokyo struggles to stop the 'JK business'.
          The Guardian.
          <br/>
          <a href={"https://www.theguardian.com/cities/2019/jun/15/tokyo-pink-bus-campaign-seeks-to-protect-schoolgirls-from-escort-scouts-jk-business"}>https://www.theguardian.com/cities/2019/jun/15/tokyo-pink-bus-campaign-seeks-to -protect-schoolgirls-from-escort-scouts-jk-business</a>
          <br/>
          <br/>
          Mina Le. (2021. January 24) Let’s talk about Japanese Schoolgirl.
          <br/>
          <a href={"https://www.youtube.com/watch?v=BBnlC9lrKVM"}>https://www.youtube.com/watch?v=BBnlC9lrKVM</a>
        </Typography>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <Box style={{textAlign: "center"}}>
          <img src={"WDKA.png"} alt={"WDKA logo"}/>
        </Box>
      </Container>
    </>
  );
}