import React, { useState } from 'react';
import '../styles/ExpandingDot.css';
import {Stack, Typography} from "@mui/material";

const ExpandingDot = ({ imageSrc, title, text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`expanding-dot ${isExpanded ? 'expanded' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isExpanded && (
        <Stack direction={"row"}>
          <img src={imageSrc} alt="" className="expanding-dot-image" />
          <Stack direction={"column"}>
            <div className="expanding-dot-title">
              <Typography variant={"h6"} fontFamily={"Helvetica"} fontWeight={"bold"}>{title}</Typography>
            </div>
            <div className="expanding-dot-text">
              <Typography variant={"body1"}>{text}</Typography>
            </div>
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default ExpandingDot;
